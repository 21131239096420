<!-- Start Sidemenu Area -->
<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a routerLink="/dashboard" class="navbar-brand d-flex
            align-items-center">
            <img src="assets/img/main-logo.png" alt="image">
        </a>
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>
    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
                Main
            </li>

            <!-- sidebar for admin and SuperAdmin -->
            <ng-container *ngIf="['Admin','SuperAdmin'].includes(roleName)">
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a routerLink="/dashboard" class="nav-link">
                        <span class="icon"><i class='bx bx-home-circle'></i></span>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/linkGeneration" class="nav-link">
                                <span class="icon"><i class='bx bx-plus-circle'></i></span>
                                <span class="menu-title">Link Generation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-link-generation" class="nav-link">
                                <span class="icon"><i class='bx bx-plus-circle'></i></span>
                                <span class="menu-title">Customer Link Generation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/itemMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Part Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/costBreakup" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Cost Breakup Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/payment-terms" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Payment Terms Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/manufacturingProcessList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">manufacturing Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/materialConstructionList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Material of Construction Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/othercategorymaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Other Category Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/buMaster" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">BU Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/bussinessUnitNumber" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Bussiness Unit Number Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/materialGrade" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Material Grade Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/materialForm" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Material Form Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/supplierdocumentMaster" class="nav-link"
                                routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Doc Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/documentMaster" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Doc Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/customerBuMaster" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer BU Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/branchPlant" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Branch Plant Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/part-drawing" class="nav-link" routerLinkActive="active mm-active">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Part Drawing Master</span>
                            </a>
                        </li>
                    </ul>
                </li>


                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierUploadedDocList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Documents</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierChangeRequestLogs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Change Request</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplier-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Upload Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplier-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Audit Logs</span>
                            </a>
                        </li>
                      
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Document</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP Document</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/ppapDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">RFQ Screens</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/requestForQuotation" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Request For Quotation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierAssignList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Assign List</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierDashboard" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">RFQ Reply</span>
                            </a>
                        </li>

                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/openEnquiry"
                                (click)="reloadPage('/enquiryPurchase/openEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Open Enquiry Comparison</span>
                            </a>
                        </li>
                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/closeEnquiry"
                                (click)="reloadPage('/enquiryPurchase/closeEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Closed Enquiry Comparison</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">User</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/userRegistration" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Register User</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/generated-asn" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">ASN List</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Customer</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-list" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Upload Doc</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Audit Logs</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customerChangeRequestLogs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Change Request</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/order-status" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Order Status</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
            </ng-container>


            <!-- sidebar for Customer -->
            <ng-container *ngIf="roleName == 'Customer'">
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Customer</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-edit-form" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Form</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Upload Doc</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customerRaiseChangeRequest" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Raise Request</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/order-status" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Order Status</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
            </ng-container>

            <!-- sidemenu for user -->
            <ng-container *ngIf="roleName == 'User' || roleName == 'Normal User'">
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/linkGeneration" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Link Generation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/itemMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Part Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/costBreakup" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Cost Breakup Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierdocumentMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Doc Master</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link"
                        routerLinkActive="active mm-active">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierUploadedDocList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Documents</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierChangeRequests" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Change Approval</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker2">
                            <a routerLink="/assessmentDoc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Assessment Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active"
                            *ngIf="userRole.checker2 || userRole.checker1">
                            <a routerLink="/supplier-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Upload Supplier Document</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level" >
                        <li class="nav-item" routerLinkActive="active mm-active" >
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/generated-asn" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">ASN List</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">RFQ</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/requestForQuotation" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Request For Quotation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierAssignList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Assign List</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierDashboard" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">RFQ Reply</span>
                            </a>
                        </li>

                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/openEnquiry"
                                (click)="reloadPage('/enquiryPurchase/openEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Open Enquiry Comparison</span>
                            </a>
                        </li>
                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/closeEnquiry"
                                (click)="reloadPage('/enquiryPurchase/closeEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Closed Enquiry Comparison</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Document</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP Document</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/ppapDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <!-- sidemenu for finance -->
            <ng-container *ngIf="roleName == 'Finance'">
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/linkGeneration" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Link Generation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/itemMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Part Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/costBreakup" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Cost Breakup Master</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/supplierdocumentMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Document Master</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier List</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierUploadedDocList" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Supplier Documents</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierChangeRequests" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Supplier Change Request</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker2">
                            <a routerLink="/assessmentDoc" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Assessment Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker2">
                            <a routerLink="/supplier-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Upload Supplier Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplier-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Audit Logs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/generated-asn" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">ASN List</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Customer</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Audit Logs</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/order-status" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Order Status</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">RFQ</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/requestForQuotation" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Request For Quotation</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierAssignList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Assign List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierDashboard" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">RFQ Reply</span>
                            </a>
                        </li>

                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/openEnquiry"
                                (click)="reloadPage('/enquiryPurchase/openEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Open Enquiry Comparison</span>
                            </a>
                        </li>
                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/closeEnquiry"
                                (click)="reloadPage('/enquiryPurchase/closeEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Closed Enquiry Comparison</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Document</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP Document</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/ppapDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Document</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <!-- <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP Document</span>
                            </a>
                        </li>
                        
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/ppapDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP Document</span>
                            </a>
                        </li> -->
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <!-- sidemenu for Customer Cs Team Finance Team Approval Team -->
            <ng-container
                *ngIf="roleName == 'CS Team' || roleName == 'Finance-JDE' || roleName == 'Customer-JDE' || roleName == 'Customer-Approval'">
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Customer</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-link-generation" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Link Generation</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customerRaiseChangeRequest" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Raise Request</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-list" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Upload Doc</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/documentMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Doc Master</span>
                            </a>
                        </li> -->

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customerRaiseChangeRequestList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Change Approval</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customer-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Audit Logs</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/customerChangeRequestLogs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Customer Changes Logs</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active mm-active" *ngIf="roleName == 'CS Team' || roleName == 'Finance' || roleName == 'Customer-Approval'">
                            <a routerLink="/order-status" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Order Status</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/generated-asn" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">ASN List</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="roleName !== 'Finance-JDE'">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="roleName == 'Finance-JDE'">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplier-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Audit Logs</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>


            <!-- sidemenu for bussiness head -->
            <ng-container *ngIf="roleName=='Bussiness head'">
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Masters</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/linkGeneration" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Link Generation</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/itemMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Part Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/costBreakup" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Cost Breakup Master</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier List</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierUploadedDocList" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Supplier Documents</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierChangeRequests" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Supplier Change Request</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker2">
                            <a routerLink="/assessmentDoc" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Assessment Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active"
                            *ngIf="userRole.checker2 || userRole.checker1">
                            <a routerLink="/supplier-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Upload Supplier Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                            <a routerLink="/supplier-audit-logs" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Audit Logs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level"   >
                        <li class="nav-item" routerLinkActive="active mm-active" >
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/generated-asn" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">ASN List</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">RFQ</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/requestForQuotation" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Request For Quotation</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierAssignList" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier Assign List</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/supplierDashboard" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">RFQ Reply</span>
                            </a>
                        </li>

                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/openEnquiry"
                                (click)="reloadPage('/enquiryPurchase/openEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Open Enquiry Comparison</span>
                            </a>
                        </li>
                        <li class="nav-item" style="cursor: pointer;" routerLinkActive="active mm-active">
                            <a class="nav-link" routerLink="/enquiryPurchase/closeEnquiry"
                                (click)="reloadPage('/enquiryPurchase/closeEnquiry')">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Closed Enquiry Comparison</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Document</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP Document</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/ppapDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP Document</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active" *ngIf="userRole.checker1 || userRole.checker2">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <!-- sidebar for supplier -->
            <ng-container *ngIf="roleName == 'Supplier'">
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a routerLink="/supplierDashboard" class="nav-link">
                        <span class="icon"><i class='bx bx-wallet'></i></span>
                        <span class="menu-title">Supplier Dashboard</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Supplier Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Supplier List</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplierOnboarding" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Supplier Onboarding</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/raiseChangeRequest" class="nav-link">
                                <span class="icon"><i class='bx bx-copy'></i></span>
                                <span class="menu-title">Raise Change Request</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/supplier-upload-doc" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Upload Supplier Document</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Order Details</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/myOrders" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Orders Details</span>
                            </a>
                        </li>   
                    </ul>
                </li>
                 -->
                 <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">JDE data</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/jde-data-listing" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">JDE data List</span>
                            </a>
                        </li>
                            <li class="nav-item" routerLinkActive="active mm-active">
                                <a routerLink="/generated-asn" class="nav-link">
                                    <span class="icon"><i class='bx bx-wallet'></i></span>
                                    <span class="menu-title">ASN List</span>
                                </a>
                            </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Doc</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/apqpDocument" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">APQP</span>
                            </a>
                        </li>

                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/PPAP" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">PPAP</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/toolLoanAgreement" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Tool Loan Agreement</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Reports</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active mm-active">
                            <a routerLink="/report" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <li class="nav-item" routerLinkActive="active mm-active">
                <a (click)="logOut()" class="nav-link" routerLinkActive="active mm-active" style="cursor: pointer;">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>

        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->