import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { ApiServiceService } from "src/app/api-service.service";
import { Table } from "primeng/table";
import { formatDate } from "@angular/common";
import { SupplierUploadDocComponent } from "../../supplier-upload-doc/supplier-upload-doc.component";

import { AppUtility } from 'src/app/interceptor/appUtitlity';
import { Router } from "@angular/router";
import * as moment from "moment";
@Component({
  selector: 'app-asn-generation-form',
  templateUrl: './asn-generation-form.component.html',
  styleUrls: ['./asn-generation-form.component.scss']
})
export class AsnGenerationFormComponent {
  breadcrumb = [
    {
      title: "ASN-Generation",
      subTitle: "Dashboard",
    },
  ];
  selectedData: any[] = [];
  ASNform: FormGroup;
  route: any;
  i: any;
  documentList: any;

  constructor(
    private fb: FormBuilder,
    private _apiService: ApiServiceService,
    private _utility: AppUtility,
    private router: Router

  ) {

  }

  @ViewChild('dt2') dataTable: Table;

  ngOnInit() {
    this.loadSelectedData();
    this.getStatic();
  }
  async getStatic() {
    await this._apiService.getDocument().then((res: any) => {
      if (res.success) {
        this.documentList = res.returnValue.filter(res => res.documentType == 'Supplier');
      } else {
        this.documentList = [];
      }
    })
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }
  loadSelectedData() {
    this.selectedData = this._apiService.getSelectedData(); // Fetch data from service
    console.log(this.selectedData);

    this.ASNform = this.fb.group({
      items: this.fb.array(this.selectedData.map(item => this.createItemFormGroup(item))),
      invoice_doc: new FormControl(null, Validators.required),
      delivery_doc: new FormControl(null, Validators.required),
      eway_doc: new FormControl(''),
      docs: this.fb.array([]),
      invoice_num: new FormControl(null, [Validators.required]),
      InvoiceDate:new FormControl(null, [Validators.required])


    });
    console.log(this.ASNform.controls, "values");

    this.listenForShipChanges();
  }
  getdocs(): FormArray {
    return this.ASNform.get('docs') as FormArray;
  }
  addDocRow(string: any, index: any) {
    let ClothesArray = this.getdocs();
    if (string == 'add') {
      let newclothesArray = new FormGroup({
        fileName: new FormControl(''),
        file: new FormControl(''),
      });
      ClothesArray.insert(index + 1, newclothesArray);
    } else {
      ClothesArray.removeAt(index);
    }
  }


  createItemFormGroup(item: any): FormGroup {
    return this.fb.group({
      id: [item.orderNumber],
      poid:[item.id],
      itemNumber: [item.itemNumber.trim(), Validators.required],
      itemDesc: [item.itemDesc, Validators.required],
      orderDate: new FormControl(this.getFormattedDate(item.orderDate), [Validators.required]),
      requestedDate: new FormControl(this.getFormattedDate(item.requestedDate), [Validators.required]),
      schPickDate: new FormControl(this.getFormattedDate(item.schPickDate), [Validators.required]),
      quantityOpen: [item.quantityOpen, Validators.required],
      unitPrice: [item.unitPrice, Validators.required],
      ship: [item.ship !== null ? item.ship : 0, [Validators.required]],
      shipAmount: [{ value: '', disabled: true }],
      linenumber:[item.lineNumber],
      ordertype:[item.orderType]

    },
      { validators: this.shipLessThanOpenQtyValidator() });
  }
  shipLessThanOpenQtyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const quantityOpen = control.get('quantityOpen')?.value;
      const ship = control.get('ship')?.value;
          
      if (ship > quantityOpen) {
        return { shipExceedsOpen: true }; 
      }
      return null; 
    };
  }

  getFormattedDate(date: string): string {
    if (date) {
      const formattedDate = formatDate(date, 'dd/MM/yyyy', 'en-US'); // Format the date properly
      return formattedDate.trim(); // Ensure the date is trimmed
    }
    return '';
  }

  get items(): FormArray {
    return this.ASNform.get('items') as FormArray;
  }

  listenForShipChanges(): void {
    const itemsArray = this.ASNform?.get('items') as FormArray;

    itemsArray?.controls?.forEach((control, index) => {
      control.get('ship')?.valueChanges.subscribe((shipValue: number) => {
        const unitPrice = control.get('unitPrice')?.value;
        const shipAmount = shipValue && unitPrice ? shipValue * unitPrice : 0;
        control.get('shipAmount')?.setValue(shipAmount, { emitEvent: false });
      });
    });
  }


  onSubmit() {
    if (this.ASNform.valid) {
      this._utility.loader(true);
      const items = this.ASNform.value.items.map(item => ({
        // ...item,
        orderNumber: (item.id),
        itemdesc:(item.itemDesc),
        ordertype:(item.ordertype),
        itemnumber:(item.itemNumber),
        linenumber:(item.linenumber),
        Poid:(item.poid),
        ship: (item.ship), // Ensure ship is treated as a number
        QuantityOpen: (item.quantityOpen),
        Value: item.unitPrice * (item.ship) // Calculate shipAmount
      }));
      console.log(items.ship);


      // Create the payload
      const payload = new FormData();
      console.log(this.ASNform.controls.invoice_doc, "invoice");
      console.log(this.ASNform.value.docs.file);
      this.ASNform.value.docs.forEach((doc: any) => {
        payload.append(doc.fileName, doc.file);
      });
      payload.append('ItemsJson', JSON.stringify(items))


      payload.append('Invoice', this.ASNform.controls['invoice_doc'].value)
      payload.append('DeliveryChaalan', this.ASNform.controls['delivery_doc'].value)
      payload.append('EwayBill', this.ASNform.controls['eway_doc'].value),
      payload.append('InvoiceNumber', this.ASNform.controls['invoice_num'].value),
      payload.append('InvoiceDate', moment(this.ASNform.controls['InvoiceDate'].value).format('DD/MM/YYYY'));


      // Call the API service with the payload
      this._apiService.asegeneration(payload)
        .then(response => {
          console.log('API response:', response);
          this._utility.loader(false);
          this._apiService.showMessage(response.message, 'success');
          setTimeout(() => {
            
            this.router.navigateByUrl("/generated-asn");
          }, 1000);
        })
        .catch(error => {
          console.error('API error:', error);
      
          // Extract error message safely
          const errorMessage = error?.error?.error || error?.message || 'Unknown error';
      
          if (error.status === 400) {
              // If the error object has a message property, display it
              if (error.error?.message) {
                  this._apiService.showMessage(error.error.message || error.statusText, 'error');
              } else {
                this._apiService.showMessage( errorMessage, 'error');

              }
          } else {
              // Handle other errors
              this._apiService.showMessage('An unexpected error occurred.', errorMessage);
          }
      
          // Ensure the loader is hidden after an error
          this._utility.loader(false);
      });
      

    }
    else {
      this._apiService.showMessage("Please fill form details", "error");
    }
  }
  uploadDoc(event: Event, fileType: string, index?: number) {
    const file = this._utility.onFileChange(event); 
    if (!file) {
      console.error('Invalid file selected:', file);
      return;
    } 

    switch (fileType) {
      case 'invoice':
        this.ASNform.controls['invoice_doc'].setValue(file); // Set invoice file
        break;

      case 'delivery':
        this.ASNform.controls['delivery_doc'].setValue(file); // Set delivery file
        break;

      case 'eway':
        this.ASNform.controls['eway_doc'].setValue(file); // Set eway file
        break;
      case 'add_doc':
        if (index !== undefined) {
          const docsArray = this.ASNform.get('docs') as FormArray; // Access the FormArray
          console.log(docsArray);

          const docGroup = docsArray.at(index) as FormGroup; // Get the FormGroup at the specified index
          console.log(docsArray);
          if (docGroup) {
            docGroup.controls['file'].setValue(file); // Set the other_doc field in FormGroup
          }
          console.log(docGroup.controls['file'].value);

        }
        break;
      default:
        console.warn('Unsupported file type:', fileType);
    }

    console.log(`${fileType} document uploaded successfully`, file);
  }

  calculateTotalShipAmount(): number {
    return this.items.controls.reduce((acc, control) => {
      return acc + (control.get('shipAmount')?.value || 0);
    }, 0);
  }

}
