import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
@Component({
  selector: 'app-generated-asn-list',
  templateUrl: './generated-asn-list.component.html',
  styleUrls: ['./generated-asn-list.component.scss'],
  providers: [DatePipe]
})

export class GeneratedAsnListComponent {
  breadcrumb = [
    {
      title: 'Generated ASN List',
      subTitle: 'Dashboard'
    }
  ]
  selectedJde: any = {}; // To store selected object
  supplierList: any = [];
  excelData: any[] = [];
  filteredXLSData: any[] = [];
  orderCo: any
  jdedata: any[] = [];
  jdedatabyid: any[] = []
  orderLineStatus:any;
  filterval: string
  filteredJdeData: any[];
  entyDate:any;
  orderNumber: any;
  orderType: string;
  dateUpdated :any;
  foreignUnitCost :any;
  exchangeRate :any;
  currency :any;
  glOffset :any;
  buyerNumber :any;
  invoiceNumber :any;
  paymentTerm :any;
  extendedPrice :any;
  unitPrice :any;
  quantityOpen :any;
  quantityOrdered :any;
  unitOfMeasure :any;
  valueStream2 :any;
  valueStream1 :any;
  valueStreamDesc :any;
  valueStream :any;
  lastStatusDesc :any;
  lastStatus :any;
  nextStatusDesc :any;
  nextStatus :any;
  lineType :any;
  description2 :any;
  description1 :any;
  foreignExtendedPrice :any;
  orderSuffix :any;
  lineNumber :any;
  businessUnit :any;
  addressNumber :any;
  billToName :any;
  shipToNumber :any;
  shipToName :any;
  orderDate :any;
  requestedDate :any;
  scheduledPickDate :any;
  cancelDate :any;
  shortItemNumber :any;
  itemNumber :any;
  userroleName: any;
  display:boolean=false
selectedJdeList: any=[]=[]

  constructor(private _apiService: ApiServiceService, public _utility: AppUtility, private datePipe: DatePipe,
    private router: Router
  ) { }
  @ViewChild('dt2') dataTable: Table;
  ngOnInit(): void {
    this.userroleName = this._utility.getLocalStorageDetails();
  
  
    this.getdata();
  }


  getdata() {
    this._utility.loader(true);
    this._apiService.getasn()
      .then((res: any) => {
        console.log(res);
        if (res.success) {
          this.jdedata = res.data;
          console.log(this.jdedata);
        } else {
          this.jdedata = [];
        }
      })
      .catch((error) => {
         this._apiService.showMessage(error.message, "error");
        this.jdedata = [];
      })
      .finally(() => {
        this._utility.loader(false);
      });
  }
  

  isExchangeRateValid(exchangeRate: any): string | number {
    // Check if exchangeRate is a valid number, return it if true, otherwise return '-'
    return typeof exchangeRate === 'number' ? exchangeRate : '- ';
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = ''
    this.orderCo = ''
    this.orderNumber = ''
    this.orderType = ''
    this.dateUpdated = ''
    this.foreignUnitCost = ''
    this.exchangeRate = ''
    this.currency = ''
    this.glOffset = ''
    this.buyerNumber = ''
    this.invoiceNumber = ''
    this.paymentTerm = ''
    this.extendedPrice = ''
    this.unitPrice = ''
    this.quantityOpen = ''
    this.quantityOrdered = ''
    this.unitOfMeasure = ''
    this.valueStream2 = ''
    this.valueStream1 = ''
    this.valueStreamDesc = ''
    this.valueStream = ''
    this.lastStatusDesc = ''
    this.lastStatus = ''
    this.nextStatusDesc = ''
    this.nextStatus = ''
    this.lineType = ''
    this.description2 = ''
    this.description1 = ''
    this.foreignExtendedPrice = ''
    this.orderSuffix = ''
    this.lineNumber = ''
    this.businessUnit = ''
    this.addressNumber = ''
    this.billToName = ''
    this.shipToNumber = ''
    this.shipToName = ''
    this.orderDate = ''
    this.requestedDate = ''
    this.scheduledPickDate = ''
    this.cancelDate = ''
    this.shortItemNumber = ''
    this.itemNumber = ''
    this.entyDate=''
    this.orderLineStatus=''
  }
  onXLSExport() {
    let filteredBy

    for (let i = 0; i < this.jdedata.length; i++) {

      this.excelData.push({
        'S.No.': i + 1,
        'ASN Number': this.jdedata[i]?.asnNumber ?? '-',
        'Order Number': this.jdedata[i]?.orderNumber ?? '-',
        'Ship Qty': this.jdedata[i]?.ship ?? '-',
        'Total Amount': this.jdedata[i]?.value ?? '-',
        'Invoice': this.jdedata[i]?.invoicePath ?? '-',
        'Delivery Challan': this.jdedata[i]?.deliveryChaalanPath ?? '-',
        'Eway Bill': this.jdedata[i]?.ewayBillPath ?? '-',
      });


    }
    this._apiService.exportExcel(this.excelData, 'ASN-list', filteredBy);
  }
  sendSelectedData() {
    const selectedData = this.jdedata.filter(item => item.selected);
    console.log(selectedData);
    
    if (selectedData.length > 0) {
      // Navigate to the new page and pass selected data
      this._apiService.setSelectedData(selectedData); 
      this.router.navigate(['/asn-generation']);
    } else {
      this._apiService.showMessage('please select atleast one checkbox','error');

    }
  }

  openModal(index: number) {
    this._utility.loader(true);
  
    this._apiService.getgenerateddata()
      .then((res: any) => {
        console.log("API Response:", res);
  
        if (res.success) {
          this.jdedatabyid = res.data; // Make sure this is updated
        } else {
          this.jdedatabyid = [];
        }
  
        // Ensure data is available before filtering
        if (this.jdedata.length > 0 && this.jdedata[index]) {
          console.log(this.jdedata[index],"log");
          
          const selectedASN = this.jdedata[index]; // Get the clicked ASN object
          console.log(selectedASN,"adsd");
          
          const asnNumber = selectedASN.asnNumber; // Extract the ASN number
                 console.log(asnNumber,"asn");
          // Filter all ASNs that have the same ASN number
          this.selectedJdeList = this.jdedatabyid.filter(asn => asn.asnNumber === asnNumber);
  
          console.log("Filtered ASNs: ", this.selectedJdeList);
  
          this.display = true; // Show modal
        } else {
          console.error("Error: Data not found for index", index);
          this._apiService.showMessage("Data not found!", "error");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this._apiService.showMessage(error.message, "error");
        this.jdedatabyid = [];
      })
      .finally(() => {
        this._utility.loader(false);
      });
  }
  
  
  getDocumentPaths(request: any): { label: string, path: string }[] {
    const possiblePaths = [
      "qualityCertificatePath",
      "insuranceClassificationPath",
      "logisticDocumentPath",
      "certificatePath",
      "orderAcknowledgementPath",
      "proformaInvoicePath",
      "packingListPath",
      "deliveryDocumentPath",
      "certificateofOriginPath",
      "thirdPartyInsCerTPIPath",
      "warrantyCertificatePath",
      "freightClassificationPath",
      "abgpbgPath"
    ];
  
    let documents: { label: string, path: string }[] = [];
  
    // Collect all available document paths
    console.log(possiblePaths,"data");
    
    for (let path of possiblePaths) {
      if (request[path]) {
        documents.push({ label: request.itemsJson, path: request[path] });
      }
    }
    console.log(documents,"rere");
    
  
    return documents; // Return all valid document paths
  }
  
  
}
