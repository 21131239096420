<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <!-- <div class="send-email-checker2"> -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>
  <!-- </div> -->
  <!-- End Breadcrumb Area -->

  <!-- Start -->
  <div class="card finance-page">
    <p-table #dt2 [value]="jdedata" dataKey="supplierId" [rows]="10" responsiveLayout="scroll"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true" currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['asnNumber','orderNumber',
            'ship' ,'value']">
      <ng-template pTemplate="caption">
        <span class="p-input-icon-left d-flex align-items-center">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                        'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
          <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
            class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom" style="margin-left: 8px;"></button>
        </span>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th>S.No.</th>
          <th>Action</th>
          <th>ASN Number</th>
          <!-- <th>Order Number</th> -->
          <th>Ship Qty</th>
          <th>Total Amount</th>
          <th>Invoice Number</th>
          <th>Invoice Date</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-jde let-i="rowIndex">
        <tr class="p-selectable-row">
          <td>{{i+1}}</td>
          <td class="d-flex" style="text-align: center;">
            <button pButton pRipple pTooltip="Show ASN Details" type="button" icon="bx bx-show"
                class="p-button-rounded p-button-primary
                p-button-text"  (click)="openModal(i)"
                ></button>
          </td>
          <td>{{jde.asnNumber ? jde.asnNumber : '-'}}</td>
          <!-- <td>{{jde.orderNumber ? jde.orderNumber : '-'}}</td> -->
          <td>{{jde.ship ? jde.ship : '-'}}</td>
          <td>{{jde.value ? jde.value : '-'}}</td>
          <td>{{jde.invoiceNumber ? jde.invoiceNumber  : '-'}}</td>
          <td>{{jde.invoiceDate ? (jde.invoiceDate | date: 'dd/MM/yyyy'): '-'}}</td>
          <!-- <td><span><a [href]="jde.invoicePath" target="_blank" [download]="jde.invoicePath">Preview</a></span></td>
          <td><span><a [href]="jde.deliveryChaalanPath" target="_blank"
                [download]="jde.deliveryChaalanPath">Preview</a></span></td>
          <td>
            <span *ngIf="jde.ewayBillPath; else noInvoice">
              <a [href]="jde.ewayBillPath" target="_blank" [download]="jde.ewayBillPath">Preview</a>
            </span>
            <ng-template #noInvoice>-</ng-template>
          </td>
          <td>
            <ng-container *ngFor="let request of jde.asnrequest; let i = index">
              <span *ngIf="request?.qualityCertificatePath">
                <a [href]="request.qualityCertificatePath" target="_blank" [download]="request.qualityCertificatePath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.insuranceClassificationPath">
                <a [href]="request.insuranceClassificationPath" target="_blank"
                  [download]="request.insuranceClassificationPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.logisticDocumentPath">
                <a [href]="request.logisticDocumentPath" target="_blank" [download]="request.logisticDocumentPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.certificatePath">
                <a [href]="request.certificatePath" target="_blank" [download]="request.certificatePath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.orderAcknowledgementPath">
                <a [href]="request.orderAcknowledgementPath" target="_blank"
                  [download]="request.orderAcknowledgementPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.ProformaInvoicePath">
                <a [href]="request.ProformaInvoicePath" target="_blank" [download]="request.ProformaInvoicePath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.PackingListPath">
                <a [href]="request.PackingListPath" target="_blank" [download]="request.PackingListPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.DeliveryDocumentPath">
                <a [href]="request.DeliveryDocumentPath" target="_blank" [download]="request.DeliveryDocumentPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.CertificateofOriginPath">
                <a [href]="request.CertificateofOriginPath" target="_blank"
                  [download]="request.CertificateofOriginPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.ThirdPartyInsCerTPIPath">
                <a [href]="request.ThirdPartyInsCerTPIPath" target="_blank"
                  [download]="request.ThirdPartyInsCerTPIPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.WarrantyCertificatePath">
                <a [href]="request.WarrantyCertificatePath" target="_blank"
                  [download]="request.WarrantyCertificatePath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.FreightClassificationPath">
                <a [href]="request.FreightClassificationPath" target="_blank"
                  [download]="request.FreightClassificationPath">
                  {{ request.itemsJson }}
                </a>
              </span>
              <span *ngIf="request?.ABGPBGPath">
                <a [href]="request.ABGPBGPath" target="_blank" [download]="request.ABGPBGPath">
                  {{ request.itemsJson }}
                </a>
              </span>

              <span
                *ngIf="i < jde.asnrequest.length - 1 && (request?.ProformaInvoicePath||request?.PackingListPath||request?.qualityCertificatePath || request?.insuranceClassificationPath ||request?.logisticDocumentPath ||request?.certificatePath||request?.ABGPBGPath|| request?.CertificateofOriginPath ||request?.insuranceClassificationPath ||request?.WarrantyCertificatePath||request?.OrderAcknowledgementPath||request?.FreightClassificationPath||request?.ThirdPartyInsCerTPIPath||request?.DeliveryDocumentPath) ">,
              </span>
            </ng-container>


          </td> -->


        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No ASN found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex-grow-1"></div>
  </div>
  <!-- End -->
  <p-dialog header="ASN Details" [(visible)]="display" [modal]="true" [style]="{width: '70vw'}" [draggable]="false" [resizable]="false">
    <div class="container ">
    <table class="table table-bordered asnTable">
      <thead>
        <tr>
          <th>Item desc</th>
          <th>Line Number</th>
          <th>Item Number</th>
          <th>Order Type</th>
          <th>Order Number</th>
          <th>Ship Qty</th>
          <th>Total Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let jde of selectedJdeList">
          <td>{{jde.itemdesc || '-'}}</td>
          <td>{{jde.linenumber || '-'}}</td>
          <td>{{jde.itemnumber || '-'}}</td>
          <td>{{jde.ordertype || '-'}}</td>
          <td>{{ jde.orderNumber || '-' }}</td>
          <td>{{ jde.ship || '-' }}</td>
          <td>{{ jde.value || '-' }}</td>
        </tr>
      </tbody>
    </table>
  
      <div class="row mt-3" *ngIf="selectedJdeList.length > 0">
        <div class="col-lg-3 col-md-12 form-group">
          <label>Invoice Document</label>
          <span class="form-control">
            <a *ngIf="selectedJdeList[0].invoicePath" [href]="selectedJdeList[0].invoicePath" target="_blank" [download]="selectedJdeList[0].invoicePath">Preview</a>
            <span *ngIf="!selectedJdeList[0].invoicePath">-</span>
          </span>
        </div>
  
        <div class="col-lg-3 col-md-12 form-group">
          <label>Delivery Challan</label>
          <span class="form-control">
            <a *ngIf="selectedJdeList[0].deliveryChaalanPath" [href]="selectedJdeList[0].deliveryChaalanPath" target="_blank" [download]="selectedJdeList[0].deliveryChaalanPath">Preview</a>
            <span *ngIf="!selectedJdeList[0].deliveryChaalanPath">-</span>
          </span>
        </div>
  
        <div class="col-lg-3 col-md-12 form-group">
          <label>Eway Bill</label>
          <span class="form-control">
            <a *ngIf="selectedJdeList[0].ewayBillPath" [href]="selectedJdeList[0].ewayBillPath" target="_blank" [download]="selectedJdeList[0].ewayBillPath">Preview</a>
            <span *ngIf="!selectedJdeList[0].ewayBillPath">-</span>
          </span>
        </div>
  
        <div class="col-lg-12 col-md-12 form-group">
          <label>Additional Documents:</label>
          <div class="form-control">
            <span *ngFor="let request of selectedJdeList[0].asnrequest; let i = index">
              <ng-container *ngFor="let doc of getDocumentPaths(request); let j = index">
                <a [href]="doc.path" target="_blank" [download]="doc.path">{{ doc.label }}</a>
                <span *ngIf="!(i === selectedJdeList[0].asnrequest.length - 1 && j === getDocumentPaths(request).length - 1)">, </span>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  

  <app-copyrights></app-copyrights>

</div>