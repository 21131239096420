import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-jde-data-listing',
  templateUrl: './jde-data-listing.component.html',
  styleUrls: ['./jde-data-listing.component.scss'],
  providers: [DatePipe] 
})
export class JdeDataListingComponent {
  breadcrumb = [
    {
      title: 'JDE data List',
      subTitle: 'Dashboard'
    }
  ]
  supplierList: any = [];
  excelData: any[] = [];
  filteredXLSData: any[] = [];
  jdedata:any[]=[
    // {
    //         "orderCo": 14200,
    //         "orderNumber": "24006533",
    //         "orderType": "OP",
    //         "lineNumber": 10.000,
    //         "orderSuffix": "000",
    //         "businessUnit": 142001,
    //         "addressNumber": 21056593,
    //         "billToName": "ACE LASER WORKS                         ",
    //         "shipToNumber": 142001,
    //         "shipToName": "IDEX INDIA PVT. LTD.  SANAND            ",
    //         "orderDate": "2024-05-24T00:00:00",
    //         "requestedDate": "2024-07-15T00:00:00",
    //         "schPickDate": "2024-07-15T00:00:00",
    //         "cancelDate": "2000-01-01T00:00:00",
    //         "shortItemNo": 7888343,
    //         "itemNumber": "FM200071                 ",
    //         "itemDesc": "Bracket,MDU                   ",
    //         "itemDesc2": "Slim Dispenser                ",
    //         "lineType": "S ",
    //         "lastStatus": 400,
    //         "lastStatusDesc": "",
    //         "supplierId": 20,
    //         "nextStatusDesc": "",
    //         "valueStream": "LOC",
    //         "valueStreamDesc": "",
    //         "valueStream1": "FFM",
    //         "valueStream2": "G03",
    //         "um": "EA",
    //         "quantityOrdered": 150.000,
    //         "quantityOpen": 14.000,
    //         "unitPrice": 255.720,
    //         "extPrice": 38358.000,
    //         "paymentTerms": "N30",
    //         "invoiceNumber": "ALW/24-25/00740     ",
    //         "buyerNumber": "10774",
    //         "glOffset": "FE10",
    //         "currency": "INR",
    //         "exchangeRate": "",
    //         "foreignUnitCost": 0.000,
    //         "foreignExtPrice": 0.000,
    //         "entyDate": "2024-10-24T12:00:04.407",
    //         "orderLineStatus": "Open",
    //         "dateUpdated": "2000-01-01T00:00:00",
    //         "isActive": null,
    //         "clientId": 0,
    //         "crDate": null
    //     },
    //     {
    //         "orderCo": 14200,
    //         "orderNumber": "24006533",
    //         "orderType": "OP",
    //         "lineNumber": 14.000,
    //         "orderSuffix": "000",
    //         "businessUnit": 142001,
    //         "addressNumber": 21056593,
    //         "billToName": "ACE LASER WORKS                         ",
    //         "shipToNumber": 142001,
    //         "shipToName": "IDEX INDIA PVT. LTD.  SANAND            ",
    //         "orderDate": "2024-05-24T00:00:00",
    //         "requestedDate": "2024-07-08T00:00:00",
    //         "schPickDate": "2024-07-08T00:00:00",
    //         "cancelDate": "2000-01-01T00:00:00",
    //         "shortItemNo": 7888343,
    //         "itemNumber": "FM200071                 ",
    //         "itemDesc": "Bracket,MDU                   ",
    //         "itemDesc2": "Slim Dispenser                ",
    //         "lineType": "S ",
    //         "supplierId": 400,
    //         "lastStatusDesc": "",
    //         "nextStatus": 400,
    //         "nextStatusDesc": "",
    //         "valueStream": "LOC",
    //         "valueStreamDesc": "",
    //         "valueStream1": "FFM",
    //         "valueStream2": "G03",
    //         "um": "EA",
    //         "quantityOrdered": 550.000,
    //         "quantityOpen": 200.000,
    //         "unitPrice": 255.720,
    //         "extPrice": 140646.000,
    //         "paymentTerms": "N30",
    //         "invoiceNumber": "ALW/24-25/01342     ",
    //         "buyerNumber": "10774",
    //         "glOffset": "FE10",
    //         "currency": "INR",
    //         "exchangeRate": "",
    //         "foreignUnitCost": 0.000,
    //         "foreignExtPrice": 0.000,
    //         "entyDate": "2024-10-24T12:00:04.69",
    //         "orderLineStatus": "Open",
    //         "dateUpdated": "2000-01-01T00:00:00",
    //         "isActive": null,
    //         "clientId": 0,
    //         "crDate": null
    //     },
    //     {
    //         "orderCo": 14200,
    //         "orderNumber": "24006533",
    //         "orderType": "OP",
    //         "lineNumber": 16.000,
    //         "orderSuffix": "000",
    //         "businessUnit": 142001,
    //         "addressNumber": 21056593,
    //         "billToName": "ACE LASER WORKS                         ",
    //         "shipToNumber": 142001,
    //         "shipToName": "IDEX INDIA PVT. LTD.  SANAND            ",
    //         "orderDate": "2024-05-24T00:00:00",
    //         "requestedDate": "2024-07-15T00:00:00",
    //         "schPickDate": "2024-07-15T00:00:00",
    //         "cancelDate": "2000-01-01T00:00:00",
    //         "shortItemNo": 7888347,
    //         "itemNumber": "FM200094                 ",
    //         "itemDesc": "Subframe Right Asm            ",
    //         "itemDesc2": "Slim Dispenser                ",
    //         "lineType": "S ",
    //         "lastStatus": 400,
    //         "lastStatusDesc": "",
    //         "supplierId": 40,
    //         "nextStatusDesc": "",
    //         "valueStream": "LOC",
    //         "valueStreamDesc": "",
    //         "valueStream1": "FFM",
    //         "valueStream2": "G03",
    //         "um": "EA",
    //         "quantityOrdered": 100.000,
    //         "quantityOpen": 7.000,
    //         "unitPrice": 953.420,
    //         "extPrice": 95342.000,
    //         "paymentTerms": "N30",
    //         "invoiceNumber": "ALW/24-25/01342     ",
    //         "buyerNumber": "10774",
    //         "glOffset": "FE10",
    //         "currency": "INR",
    //         "exchangeRate": "",
    //         "foreignUnitCost": 0.000,
    //         "foreignExtPrice": 0.000,
    //         "entyDate": "2024-10-24T12:00:04.957",
    //         "orderLineStatus": "Open",
    //         "dateUpdated": "2000-01-01T00:00:00",
    //         "isActive": null,
    //         "clientId": 0,
    //         "crDate": null
    //     },
  ]
  filterval:string
  filteredJdeData: any[];


constructor(private _apiService: ApiServiceService,public _utility: AppUtility,private datePipe: DatePipe) { }
@ViewChild ('dt2') FilteredData:Table;
ngOnInit(): void {
  this.getdata();
  // this._utility.loader(true);
  // this._apiService.getData().subscribe(data => {
  //   this.jdedata = data; // Store the JSON data in a variable
  //   console.log("data",data);
  //   this._utility.loader(false);
  // });




}


getdata(){
  this._utility.loader(true);
  this._apiService.getjdedata().then((res:any)=>{
    console.log(res);
    
    this._utility.loader(false);
    if (res.success == true) {
      this.jdedata = res.returnValue;
      console.log(this.jdedata);
      

    }
    else {
      this.jdedata = [];
    }

  })
}
// In your component TypeScript file
// In your component TypeScript file
isExchangeRateValid(exchangeRate: any): string | number {
  // Check if exchangeRate is a valid number, return it if true, otherwise return '-'
  return typeof exchangeRate === 'number' ? exchangeRate : '- ';
}



reset(dt2) {
  dt2.reset();
  this.filterval=''
}
onXLSExport() {
  let filteredBy

  for (let i = 0; i < this.jdedata.length; i++) {
   
    this.excelData.push({
      'S.No.': i + 1,
      'Order Co': this.jdedata[i]?.orderCo ?? '-',
      'Order Number': this.jdedata[i]?.orderNumber ?? '-',
      'Order Type': this.jdedata[i]?.orderType ?? '-',
      'Order Suffix': this.jdedata[i]?.orderSuffix ?? '-',
      'Line Number': this.jdedata[i]?.lineNumber ?? '-',
      'Business Unit': this.jdedata[i]?.businessUnit ?? '-',
      'Address Number': this.jdedata[i]?.addressNumber ?? '-',
      'Bill To Name': this.jdedata[i]?.billToName ?? '-',
      'Ship To Number': this.jdedata[i]?.shipToNumber ?? '-',
      'Ship to Name': this.jdedata[i]?.shipToName ?? '-',
      'Order Date': this.datePipe.transform(this.jdedata[i]?.orderDate, 'MM/dd/yyyy') ?? '-',
      'Requested Date': this.datePipe.transform(this.jdedata[i]?.requestedDate, 'MM/dd/yyyy') ?? '-',
      'Scheduled Pick Date': this.datePipe.transform(this.jdedata[i]?.scheduledPickDate, 'MM/dd/yyyy') ?? '-',
      'Cancel Date': this.datePipe.transform(this.jdedata[i]?.cancelDate, 'MM/dd/yyyy') ?? '-',
      'Short Item Number': this.jdedata[i]?.shortItemNumber ?? '-',
      'Item Number': this.jdedata[i]?.itemNumber ?? '-',
      'Description 1': this.jdedata[i]?.description1 ?? '-',
      'Description 2': this.jdedata[i]?.description2 ?? '-',
      'Line Type': this.jdedata[i]?.lineType ?? '-',
      'Next Status': this.jdedata[i]?.nextStatus ?? '-',
      'Next Status Desc': this.jdedata[i]?.nextStatusDesc ?? '-',
      'Last Status': this.jdedata[i]?.lastStatus ?? '-',
      'Last Status Desc': this.jdedata[i]?.lastStatusDesc ?? '-',
      'Value Stream': this.jdedata[i]?.valueStream ?? '-',
      'Value Stream Desc': this.jdedata[i]?.valueStreamDesc ?? '-',
      'Value Stream 1': this.jdedata[i]?.valueStream1 ?? '-',
      'Value Stream 2': this.jdedata[i]?.valueStream2 ?? '-',
      'Unit Of Measure': this.jdedata[i]?.unitOfMeasure ?? '-',
      'Quantity Ordered': this.jdedata[i]?.quantityOrdered ?? '-',
      'Quantity Open': this.jdedata[i]?.quantityOpen ?? '-',
      'Unit Price': this.jdedata[i]?.unitPrice ?? '-',
      'Extended Price': this.jdedata[i]?.extendedPrice ?? '-',
      'Payment Term': this.jdedata[i]?.paymentTerm ?? '-',
      'Invoice Number': this.jdedata[i]?.invoiceNumber ?? '-',
      'Buyer Number': this.jdedata[i]?.buyerNumber ?? '-',
      'GL Offset': this.jdedata[i]?.glOffset ?? '-',
      'Currency': this.jdedata[i]?.currency ?? '-',
      'Exchange Rate': this.jdedata[i]?.exchangeRate ?? '-',
      'Foreign Unit Cost': this.jdedata[i]?.foreignUnitCost ?? '-',
      'Foreign Extended Price': this.jdedata[i]?.foreignExtendedPrice ?? '-',
      'Entry Date': this.datePipe.transform(this.jdedata[i]?.entyDate, 'MM/dd/yyyy') ?? '-',
      'Order Line Status': this.jdedata[i]?.orderLineStatus ?? '-',
      'Date Updated': this.datePipe.transform(this.jdedata[i]?.dateUpdated, 'MM/dd/yyyy') ?? '-'
    });
    
    
  }
  this._apiService.exportExcel(this.excelData, 'JDE-list' , filteredBy);
}

}