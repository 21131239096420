<app-dashboard-sidemenu></app-dashboard-sidemenu>

<div class="main-content d-flex flex-column">

  <app-dashboard-navbar></app-dashboard-navbar>

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>
  <form [formGroup]="ASNform" (ngSubmit)="onSubmit()" #asnform="ngForm">
    <div class="add-listings-box1">
      <table class="table table-bordered asnlist">
        <thead>
          <tr>
            <th>PO No.</th>
            <th>Item Number</th>
            <th>Item Description</th>
            <th>ORDER date</th>
            <th>Req date</th>
            <th>Delivery Date</th>
            <th>Open Qty</th>
            <th>Price</th>
            <th>Ship<span class="star_class">*</span></th>
            <th>Value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
            <td style="width: 90px;"><input type="text" formControlName="id" readonly
                style="border: 1px solid #ddd; width: 90px;"></td>
            <td style="width: 90px;"><input type="text" formControlName="itemNumber" readonly
                style="border: 1px solid #ddd; width: 90px;"></td>
            <td><input type="text" formControlName="itemDesc" readonly style="border: 1px solid #ddd; width: 100%;">
            </td>
            <td style="width: 90px;"><input type="text" formControlName="orderDate" [value]="orderDate?.value" readonly
                style="width: 90px; border: 1px solid #ddd;"></td>
            <td style="width: 90px;"><input type="text" formControlName="requestedDate" [value]="requestedDate?.value"
                readonly style="width: 90px; border: 1px solid #ddd;">
            </td>
            <td style="width: 90px;"><input type="text" formControlName="schPickDate" [value]="schPickDate?.value"
                readonly style="width: 90px;border: 1px solid #ddd;"></td>
            <td style="width: 60px;"><input type="text" formControlName="quantityOpen" readonly
                style="border: 1px solid #ddd; width: 60px;"></td>
            <td style="width: 90px;"><input type="text" formControlName="unitPrice" readonly
                style="border: 1px solid #ddd; width: 90px;"> </td>
            <td style="width: 90px;"> <input type="text" formControlName="ship"
                style="border: 1px solid #ddd; width: 90px;" (keypress)="keyPress($event)" maxlength="5"
                placeholder="Enter Ship No."
                [class.is-invalid]="asnform.submitted && item.get('ship')?.invalid || item.get('ship')?.touched && item.get('ship')?.invalid" />
              <div class="error"
                *ngIf="asnform.submitted && item.get('ship')?.invalid || item.get('ship')?.touched && item.get('ship')?.invalid">
                Please enter a Ship value
              </div>
              <div class="error" *ngIf="item.errors?.shipExceedsOpen">
                Ship quantity cannot exceed open quantity.
              </div>
            </td>
            <td style="width: 90px;"><input type="text" formControlName="shipAmount" readonly style="width:90px"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex; justify-content: end; margin-top: 10px;">
        <strong>Total Ship Amount:</strong> {{ calculateTotalShipAmount() | number:'1.2-2' }}
      </div>


      <div class="row">
        <div class="col-lg-3 col-md-12 form-group">
          <label for="invoice">Invoice Number<span class="star_class">*</span></label>
          <input type="input" class="form-control" formControlName="invoice_num" placeholder="Enter Invoice Number"
            maxlength="10"
            [class.is-invalid]="asnform.submitted && ASNform.get('invoice_num')?.invalid || ASNform.get('invoice_num')?.touched && ASNform.get('invoice_num')?.invalid">
          <div class="error"
            *ngIf="asnform.submitted && ASNform.get('invoice_num')?.invalid || ASNform.get('invoice_num')?.touched && ASNform.get('invoice_num')?.invalid">
            Please enter Invoice Number
          </div>
        </div>
        <div class="col-lg-3 col-md-12 form-group">
          <label for="invoice">Invoice Date<span class="star_class">*</span></label>
          <p-calendar formControlName="InvoiceDate" placeholder="Select Invoice Date" [readonlyInput]="true"
            [showIcon]="true" [class.is-invalid]="asnform.submitted &&
          ASNform.get('InvoiceDate')?.invalid ||
          ASNform.get('InvoiceDate')?.invalid &&
          ASNform.get('InvoiceDate')?.touched"></p-calendar>
          <div class="invalid-feedback"> Please Select Date</div>
        </div>
      </div>
      <div class="row">
        <h3>Attach Documents</h3>
        <div class="col-lg-3 col-md-12 form-group">
          <label for="document1">Invoice<span class="star_class">*</span></label>
          <input type="file" class="form-control" (change)="uploadDoc($event,'invoice')">
          <div class="error" *ngIf="asnform.submitted &&
                            ASNform.get('invoice_doc')?.invalid ||
                            ASNform.get('invoice_doc')?.invalid &&
                            ASNform.get('invoice_doc')?.touched">Please select Invoice Document</div>
        </div>
        <div class="col-lg-3 col-md-12 form-group">
          <label for="document2">Delivery Challan<span class="star_class">*</span></label>
          <input type="file" class="form-control" (change)="uploadDoc($event,'delivery')">
          <div class="error" *ngIf="asnform.submitted &&
                    ASNform.get('delivery_doc')?.invalid ||
                    ASNform.get('delivery_doc')?.invalid &&
                    ASNform.get('delivery_doc')?.touched">Please select Delivery Document</div>
        </div>
        <div class="col-lg-3 col-md-12 form-group">
          <label for="document2">eway bill</label>
          <input type="file" class="form-control" (change)="uploadDoc($event,'eway')">

        </div>
        <h3 for="document2"> Add Other Documents</h3>

        <div class="col-auto d-flex mb-3 align-items-end">

          <p-button type="button" icon="bx bx-plus" (click)="addDocRow('add',i)" iconPos="right"
            class="secondary-btn mr-2">
          </p-button>
        </div>
        <div formArrayName="docs">
          <div class="row my-2" *ngFor="let doc of getdocs().controls; index as i; last as last" [formGroupName]="i">
            <div class="col-lg-3 col-md-12 form-group">
              <label for="status">Document Name<span class="star_class">*</span></label>
              <select formControlName="fileName" class="form-control form-select">
                <option value="">Select Document Name</option>
                <ng-container *ngFor="let document of documentList">
                  <option [value]="document.documentName">{{document.documentName}}</option>
                </ng-container>
              </select>
            </div>
            <div class="col-lg-3 col-md-12 form-group">
              <label>Select doc</label>
              <input type="file" class="form-control" (change)="uploadDoc($event,'add_doc',i)">
            </div>
            <div class="col-auto d-flex mb-3 align-items-end">
              <!-- Remove Button -->
              <p-button type="button" icon="bx bx-minus" (click)="addDocRow('delete', i)" iconPos="right"
                class="secondary-btn ms-2">
              </p-button>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; display: flex; align-items: end; justify-content: end;">
        <button type="submit" class="submit_btn">Save</button>
      </div>

    </div>


  </form>

</div>